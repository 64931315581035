import React, {useEffect, useState} from "react";
import {
	deleteUserOtherChannels,
	getUsersOtherChannels,
	getUsersOtherChannelsCount,
	usersOtherChannelsExport
} from "../http/telegramUsersAPI";
import {Button, Table} from "react-bootstrap";
import VariablesSettings from "./VariablesSettings";

const OtherChannelsAccountBlock = () => {
	const [count, setCount] = useState(0);
	const [buttonInfo, setButtonInfo] = useState({
		disabled: false,
		text: 'Получить файл'
	});
	const [users, setUsers] = useState([]);

	const getData = () => {
		getUsersOtherChannelsCount()
			.then(res => setCount(res.count))
			.catch(console.error);
		getUsersOtherChannels()
			.then(setUsers)
			.catch(console.error);
	}

	useEffect(() => {
		getData()
	}, []);

	const deleteButtonHandler = (id) => {
		deleteUserOtherChannels(id)
			.then(getData)
			.catch(alert)
	}

	const banButtonHandler = (id) => {
		deleteUserOtherChannels(id, true)
			.then(getData)
			.catch(alert)
	}

	const getFileHandler = (e) => {
		setButtonInfo({
			disabled: true,
			text: 'Ожидайте...'
		})
		usersOtherChannelsExport()
			.then(async res => {
				const response = await fetch(process.env.REACT_APP_API_URL + res.urlUsersOtherChannels);
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				const blob = await response.blob();
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'usernamesOtherChannels.txt');
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
				window.URL.revokeObjectURL(url);

				getUsersOtherChannelsCount()
					.then(res => setCount(res.count))
					.catch(console.error);

				setButtonInfo({
					disabled: false,
					text: 'Получить файл'
				});
			})
			.catch(err => {
				console.error(err);
				setButtonInfo({...buttonInfo, text: 'Ошибка получения файла'})
			});
	}

	return (
		<div className={'mt-4 text-white'}>
			<VariablesSettings
				variablesArray={
					[
						'channelsForParsingUsers'
					]
				}
			/>

			<div className={'border-2 border-top pt-4'}>
				<span><b>Доступно для экспорта: </b> {count}</span>
			</div>
			<div className={'mt-3'}>
				<Button
					disabled={buttonInfo.disabled}
					onClick={getFileHandler}
				>
					{buttonInfo.text}
				</Button>
			</div>

			<div className={'mt-4'}>
				{
					users.length === 0 ? <span><b>Отствуют юзеры для отображения</b></span> :
						<Table className={'mt-3'} variant={'dark'} bordered striped>
							<tbody className={'text-white'}>
							{users.map(user =>
								<React.Fragment key={user.telegramUser.userId}>
									<tr className={''}>
										<td className={'align-middle'} style={{maxWidth: 200}}>
											{user.telegramUser.firstName}<br/>
											{user.telegramUser.lastName || ''}
										</td>
										<td className={'align-middle'}>
											{user.telegramUser.userName ? <a className={'text-white'}
											                                 href={`https://t.me/${user.telegramUser.userName}`}>@{user.telegramUser.userName}</a> : 'none'}
										</td>
										<td className={'align-middle'}>
											ID: {user.telegramUser.userId} ({user.telegramUser.userId.toString().length})
										</td>
										<td className={'align-middle'}>
											<a className={'text-white'}
											   href={`https://t.me/${user.channel.slice(1)}`}>{user.channel}</a>
										</td>
										<td className={'align-middle ' + (user.unloaded ? 'text-success' : '')}>
											Выгружен: {user.unloaded.toString()}
										</td>
										<td className={'align-middle'}>
											<Button
												onClick={() => window.open(`/messages/${user.telegramUser.userId}/${user.messageId}`)}>Сообщение</Button>
										</td>
										<td className={'align-middle'}>
											<Button
												onClick={() => deleteButtonHandler(user.id)}>Удалить</Button>
										</td>
										<td className={'align-middle'}>
											<Button
												variant={'danger'}
												onClick={() => deleteButtonHandler(user.id)}>BAN</Button>
										</td>
									</tr>

									{
										user.message &&
										<tr>
											<td colSpan={9}>
												<p style={{fontSize: 13}}>
													{user.message.message.replaceAll('\n', ' ')}
												</p>
											</td>
										</tr>
									}
								</React.Fragment>
							)}
							</tbody>
						</Table>
				}
			</div>
		</div>
	)
}

export default OtherChannelsAccountBlock;