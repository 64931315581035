import React, {useEffect, useState} from 'react';
import {Button, Table} from "react-bootstrap";
import {deleteCausesByTelegramUser, getTelegramUsersWithCauses} from "../http/telegramUsersAPI";

const PpUsersTable = () => {
	const [entities, setEntities] = useState([]);

	useEffect(() => {
		getTelegramUsersWithCauses()
			.then(res => setEntities(res.reverse()))
			.catch(console.error)
	}, []);

	const deleteCausesHandler = (telegramUserId) => {
		deleteCausesByTelegramUser(telegramUserId)
			.then(() => setEntities(prev => prev.filter(el => el.telegramUserId !== telegramUserId)))
			.catch(console.error);
	}

	return (
		<Table className={'mt-3'} variant={'dark'} bordered striped>
			<tbody className={'text-white'}>
			{entities.map(entity =>
				<React.Fragment key={entity.userId}>
					<tr>
						<td>
							{entity.firstName}<br/>
							{entity.lastName || ''}
						</td>
						<td>
							{entity.userName ? <a className={'text-white'}
							                      href={`https://t.me/${entity.userName}`}>@{entity.userName}</a> : 'none'}
						</td>
						<td>
							ID: {entity.userId} ({entity["userId"].toString().length})
						</td>
						<td>
							{
								entity.causesPPInformation.map(cause => <div key={cause.id}>
									{cause.channelName ? <a className={'text-white'}
									                        href={`https://t.me/${cause.channelName}`}>@{cause.channelName}</a> : 'none'} - {cause.count}
								</div>)
							}
						</td>
						<td>
							<Button variant={'light'} onClick={() => deleteCausesHandler(entity.id)}>Очистить</Button>
						</td>
					</tr>
				</React.Fragment>
			)}
			</tbody>
		</Table>
	);
};

export default PpUsersTable;