import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Tab, Tabs} from "react-bootstrap";
import {getAllServiceVariables, updateServiceVariables} from "../http/serviceVariableAPI";
import AccountSettings from "./AccountSettings";
import VariablesSettings from "./VariablesSettings";
import {restartServer} from "../http/serverAPI";
import OtherChannelsAccountBlock from "./OtherChannelsAccountBlock";

const ServiceVariables = () => {
	const [currentWindow, setCurrentWindow] = useState(0);
	const [currentSubWindows, setCurrentSubWindow] = useState(0);

	return (
		<Container>
			<Tabs
				className={'mt-2 d-flex justify-content-center'}
				activeKey={currentWindow}
				onSelect={key => setCurrentWindow(+key)}
			>
				<Tab
					title={'Аккаунты'}
					eventKey={0}
				>
					{
						currentWindow === 0 &&
						<Tabs
							className={'mt-2'}
							activeKey={currentSubWindows}
							onSelect={key => setCurrentSubWindow(+key)}
						>
							<Tab
								eventKey={0}
								title={'Основной аккаунт'}
							>
								{
									currentSubWindows === 0 && <AccountSettings
										sessionType={'main'}
									/>
								}
							</Tab>
							<Tab
								eventKey={1}
								title={'Вспомогательный аккаунт'}
							>
								{
									currentSubWindows === 1 && <AccountSettings
										sessionType={'sub'}
									/>
								}
							</Tab>
							<Tab
								eventKey={2}
								title={'Парс ДК'}
							>
								{
									currentSubWindows === 2 && <AccountSettings
										sessionType={'otherChannels'}
									/>
								}
							</Tab>
							<Tab
								eventKey={3}
								title={'Surfing аккаунт'}
							>
								{
									currentSubWindows === 3 && <AccountSettings
										sessionType={'surfing'}
									/>
								}
							</Tab>
							{/*<Tab*/}
							{/*    eventKey={4}*/}
							{/*    title={'AntiBot аккаунт'}*/}
							{/*>*/}
							{/*    {*/}
							{/*        currentSubWindows === 4 && <AccountSettings*/}
							{/*            sessionType={'antibot'}*/}
							{/*        />*/}
							{/*    }*/}
							{/*</Tab>*/}
						</Tabs>
					}
				</Tab>
				<Tab
					eventKey={1}
					title={'Прокси курсов'}
				>
					{
						currentWindow === 1 &&
						<VariablesSettings
							variablesArray={
								[
									'googleProxies',
								]
							}
						/>
					}
				</Tab>
				{/*<Tab*/}
				{/*    eventKey={2}*/}
				{/*    title={'AntiBot переменные'}*/}
				{/*>*/}
				{/*    {*/}
				{/*        currentWindow === 2 &&*/}
				{/*        <VariablesSettings*/}
				{/*            variablesArray={*/}
				{/*                [*/}
				{/*                    'antiBotCheckMinutes',*/}
				{/*                    'antiBotUsersAmount',*/}
				{/*                    'antiBotOffMinutes',*/}
				{/*                    'antiBotPeriodCheckSeconds',*/}
				{/*                    'antiBotAmountEvents'*/}
				{/*                ]*/}
				{/*            }*/}
				{/*        />*/}
				{/*    }*/}
				{/*</Tab>*/}
				<Tab
					eventKey={3}
					title={'Настройки курсов'}
				>
					{
						currentWindow === 3 &&
						<Tabs
							className={'mt-2'}
							activeKey={currentSubWindows}
							onSelect={key => setCurrentSubWindow(+key)}
						>
							<Tab
								title={'Основной'}
								eventKey={0}
							>
								{
									currentSubWindows === 0 && <VariablesSettings
										variablesArray={
											[
												'exchangeRatesCaption',
												'exchangeRatesButtonText',
												'exchangeRatesButtonURL',
												'exchangeCronString'
											]
										}
									/>
								}
							</Tab>
							<Tab
								title={'Дополнительный'}
								eventKey={1}
							>
								{
									currentSubWindows === 1 && <VariablesSettings
										variablesArray={
											[
												'exchangeRatesCaption2',
												'exchangeRatesButtonText2',
												'exchangeRatesButtonURL2',
												'exchangeCronString2'
											]
										}
									/>
								}
							</Tab>
						</Tabs>
					}
				</Tab>
				<Tab
					eventKey={4}
					title={'A-Podpiska'}
				>
					{
						currentWindow === 4 &&
						<VariablesSettings
							variablesArray={
								[
									'admexLimitBasicUsers',
									'aPodpiskaNotification',
									'aPodpiskaGoldenUntilDate',
									'messageForGoldenUsers',
									'messageForBasicUsers',
									'startMessageForAPodpiska',
									'exchangeChatsMuteMessage',
									'aPodpiskaDeletePreMessage',
								]
							}
						/>
					}
				</Tab>
				<Tab
					eventKey={5}
					title={'A-Helper'}
				>
					{
						currentWindow === 5 &&
						<VariablesSettings
							variablesArray={
								[
									'admexCauseMessage',
									'admexBanMessage',
									'admexPPCommandMessage'
								]
							}
						/>
					}
				</Tab>
				<Tab
					eventKey={6}
					title={'Общие настройки'}
				>
					{
						currentWindow === 6 &&
						<VariablesSettings
							variablesArray={
								[
									'orangeReplyMessage',
									'blueReplyMessage',
									'stopBotsInChats'
								]
							}
						/>
					}
					<Button
						className={'mb-2'}
						onClick={() => {
							alert('Запрос на перезапуск отправлен');
							restartServer()
								.catch(console.error);
						}}
					>
						Перезапустить проект
					</Button>
				</Tab>
			</Tabs>
		</Container>
	);
};

export default ServiceVariables;