import React, {useEffect, useState} from 'react';
import {
	getAllServiceVariablesByFilter,
	updateServiceVariables
} from "../http/serviceVariableAPI";
import {Button, Form} from "react-bootstrap";
import {sendRates} from "../http/botAPI";


const VariablesSettings = ({variablesArray}) => {
	const [variables, setVariables] = useState([]);
	const [requestStatus, setRequestStatus] = useState(null);
	const [variablesInputSettings, setVariablesInputSettings] = useState({});

	useEffect(() => {
		getAllServiceVariablesByFilter(variablesArray.join(','))
			.then(data => {
				const localVariableSettings = {};
				for (const variable of data) {
					if (['googleProxies'].includes(variable.name))
						localVariableSettings[variable.name] = {
							type: 'textarea',
							rows: 10
						}
					else if (['channelsForParsingUsers'].includes(variable.name))
						localVariableSettings[variable.name] = {
							type: 'textarea',
							rows: 5
						}
					else if (
						[
							'orangeReplyMessage',
							'blueReplyMessage',
							'exchangeRatesCaption',
							'exchangeRatesCaption2',
							'exchangeChatsMuteMessage',
							'messageForGoldenUsers',
							'messageForBasicUsers',
							'startMessageForAPodpiska',
							'admexCauseMessage',
							'admexBanMessage',
							'admexPPCommandMessage',
							'aPodpiskaNotification',
							'aPodpiskaGoldenUntilDate',
						].includes(variable.name)
					)
						localVariableSettings[variable.name] = {
							type: 'textarea',
							rows: 4
						}
					else if (['aPodpiskaDeletePreMessage', 'stopBotsInChats'].includes(variable.name)) {
						localVariableSettings[variable.name] = {
							type: 'checkbox',
						}
					} else {
						localVariableSettings[variable.name] = {
							type: 'input',
							rows: 1
						}
					}
				}
				setVariablesInputSettings(localVariableSettings);
				setVariables([...data]);
			})
			.catch(console.error)
	}, []);

	const changeValueVariableHandler = (e, id) => {
		const variable = variables.find(el => el.id === id);
		if (['aPodpiskaDeletePreMessage', 'stopBotsInChats'].includes(variable.name)) {
			variable.value = e.target.checked === true ? 'true' : 'false';
		} else {
			variable.value = e.target.value;
		}

		setVariables([...variables]);
	}

	const saveVariablesHandler = async () => {
		updateServiceVariables(variables)
			.then(() => setRequestStatus('Сохранено'))
			.catch(err => setRequestStatus(`Ошибка: ${err.message}`));
	}

	const sendRatesHandler = async () => {
		sendRates()
			.then(() => alert('Запрос отправлен'))
			.catch((e) => alert(`Ошибка отправки запроса: ${e.message}`))
	}

	return (
		<>
			{
				variables.length &&
				<Form className={'mt-3 mb-3'}>
					{variables.map(variable => (
						<Form.Group key={variable.id} className={'mt-2'}>
							<Form.Label style={{color: 'white'}}>{variable.title}</Form.Label>
							{
								!['aPodpiskaDeletePreMessage', 'stopBotsInChats'].includes(variable.name) ?
									<Form.Control
										type={'text'}
										as={variablesInputSettings[variable.name].type}
										value={variable.value}
										rows={variablesInputSettings[variable.name].rows}
										onChange={e => changeValueVariableHandler(e, variable.id)}
									/> :
									<>
										<br/>
										<span className={'text-white'} style={{marginRight: 8}}>Нет</span>
										<Form.Check
											inline
											type={'switch'}
											checked={variable.value === 'true'}
											style={{marginRight: 0}}
											onChange={e => changeValueVariableHandler(e, variable.id)}
										/>
										<span className={'text-white'}>Да</span>
									</>
							}
						</Form.Group>
					))}
					{!!requestStatus && <span className={'text-white d-block mt-2'}>{requestStatus}</span>}
					<div className={'d-flex justify-content-between'}>
						<Button
							className={'mt-3'}
							onClick={saveVariablesHandler}
						>
							Сохранить
						</Button>
						{variablesArray[0] === 'exchangeRatesCaption' &&
							<Button
								className={'mt-3'}
								onClick={sendRatesHandler}
							>
								Отправить курсы в чаты
							</Button>
						}
					</div>
				</Form>
			}
		</>
	);
};

export default VariablesSettings;